import React from "react"

import {Book, HorizontalLayoutBook} from "./books";

// CSS Modules
import book from "../styles/book.module.styl"

import constelaciones from "../images/portadas/constelaciones.png";
import cuandoMientenLasEstrellas from "../images/portadas/Cuando-mienten-las-estrellas.png";
import krampImg from "../images/portadas/kramp.png";
import ciudadInvencible from "../images/portadas/la-ciudad-invencible.png";
import nadieEnCasa from "../images/portadas/No-hay-nadie-en-casa.png";
import marcasDelAgua from "../images/portadas/Las-marcas.png";
import ultimoIntento from "../images/portadas/el_ultimo_intento.png";
import algunasVerdades from "../images/portadas/algunas_verdades.png";
import comoImagineBagdad from "../images/portadas/como_imagine_bagdad.png";
import recenPorMi from "../images/portadas/recen_por_mi.png";
import misAmigas from "../images/portadas/mis_amigas_estan_cansadas.png";
import rawAge from "../images/portadas/raw_age.png";
import ficcionesLudicas2 from "../images/portadas/ficciones2.png";
import llegadaLaHora2 from "../images/portadas/llegada2.png";
import tresTruenaBanner from "../images/portadas/tres_truenos.png";

import { graphql, StaticQuery } from "gatsby";
import { BOOK_IMG_MAP } from "../pages/libros";

const BookListGridsQuery = graphql`
  query ListaLibrosGridQuery {
    allLibrosJson {
      edges {
        node {
          author
          author2
          img
          title
          released
          authorUrl
          author2Url
          url
          purchaseLinks {
            amazon
          }
        }
      }
    }
  }
`;

export class HorizontalBookGrid extends React.Component {
  render() {

    return (
      <div>
        <div className={book.horizontalBookGrid}>
          <HorizontalLayoutBook
            img={constelaciones}
            title="Los nombres de las constelaciones"
            author="Daniel Espartaco"
            authorUrl="daniel-espartaco"
            blurb="Para narrar las aventuras, los mitos y los andares de la infancia se requiere de cierta valentía y de una aguda destreza literaria. La literatura que se encarga de narrar los pasajes íntimos de una vida debe asumir que el lector es un extraño que debe sentirse en casa: Daniel Espartaco lo logra haciendo uso de una sencillez y claridad narrativas excepcionales."
            leftCover={true}
            purchaseLinks={{
              amazon: ""
            }}
            url="los-nombres-de-las-constelaciones"
          />
          <HorizontalLayoutBook
            img={misAmigas}
            title="Mis amigas están cansadas"
            authorUrl="iveth-luna-flores"
            author="Iveth Luna Flores"
            blurb="No hay en estos poemas lugar para la auto conmiseración ni para la autoindulgencia porque si de alguien somos enemigas es siempre de nosotras mismas. Sin embargo, aprender a tratarse con ternura es aquí una de las tareas más urgentes, que Luna logra sin caer en los dogmas y los slogans de las trampas del individualismo neoliberal; los resquicios para la suavidad se disponen como una piel nueva para el mundo: un espacio para las amigas, para las compañías no-humanas, y para inventar nuevos modos de parentesco y de familia."
            leftCover={false}
            url="mis-amigas-estan-cansadas"
          />

          <HorizontalLayoutBook
            img={cuandoMientenLasEstrellas}
            title="Cuando mienten las estrellas"
            author="Jorge Castellanos"
            authorUrl="jorge-castellanos"
            blurb="En esta alucinante novela se pone en tela de juicio la veracidad del pensamiento mágico y el esoterismo, pues al ir entramando la verdadera personalidad de May Stine, o su alterego gitano “Miroslava”, se nos devela todo lo que hay detrás de la astrología, la quiromancia, el tarot y hasta dónde pueden llegar las redicciones cuando se acompañan de la desesperación."
            leftCover={true}
            url="cuando-mienten-las-estrellas"
          />
          <HorizontalLayoutBook
            img={ciudadInvencible}
            title="La ciudad invencible"
            author="Fernanda Trías"
            authorUrl="fernanda-trias"
            blurb="¿Cómo desdoblarse en una ciudad desconocida y a la vez terriblemente hosca?\n\nMirando a través de las ventanas. Mudándose. Detectando las minucias en los techos. Las luces en la noche. Los vecinos insomnes con los que se comparte, por lo menos, cierta energía excéntrica y silenciosa. La narradora de estas páginas enfrenta los embates de la memoria, de los hombres y de la muerte desde los pocos espacios que le concede la capital argentina."
            leftCover={false}
            url="la-ciudad-invencible"
          />
          <HorizontalLayoutBook
            img={tresTruenaBanner}
            title="Tres truenos"
            authorUrl="marina-closs"
            author="Marina Closs"
            blurb="Tres truenos está dividido en tres relatos, retratos o inicios de novela. La narración sucede desde las voces de tres mujeres protagonistas; cada uno de ellos contado desde la primera persona. Marina Closs explora tres temas con cada una de las voces, pudieran ser momentos comunes en la vida de una mujer: la virginidad, la paciencia, y el amor verdadero."
            leftCover={true}
            url="tres-truenos"
          />
        </div>
      </div>
    );
  }
}

export class BookGrid extends React.Component {
  render() {
    const {gridSizeClass} = this.props;
    return (
      <div>
        <div className={`${book.bookGrid} ${book.bookGridFour} ${gridSizeClass}`}>
          <Book
            img={ficcionesLudicas2}
            title="Ficciones lúdicas (2a edición)"
            author="Rodrigo Díez"
            authorUrl="rodrigo-diez"
            url="ficciones-ludicas-2a-edicion"
          />
          <Book
            img={llegadaLaHora2}
            title="Llegada la hora (2a edición)"
            author="Karla Zárate"
            authorUrl="karla-zarate"
            url="llegada-la-hora-2a-edicion"
          />
          <Book
            img={rawAge}
            title="Raw Age / La hora cruda"
            author="María Cristina Hall"
            authorUrl="maria-cristina-hall"
            url="raw-age-la-hora-cruda"
          />
          <Book
            img={misAmigas}
            title="Mis amigas están cansadas"
            author="Iveth Luna Flores"
            authorUrl="iveth-luna-flores"
            url="mis-amigas-estan-cansadas"
          />
          <Book
            img={algunasVerdades}
            title="Algunas verdades están afuera pero de otras es imposible saberlo"
            author="Luis Reséndiz"
            authorUrl="luis-resendiz"
            url="algunas-verdades"
          />
          <Book
            img={recenPorMi}
            title="Recen por mí"
            author="Andrea Alzati"
            authorUrl="andrea-alzati"
            url="recen-por-mi"
          />
          <Book
            img={comoImagineBagdad}
            title="Cómo imaginé Bagdad y cómo la encontré"
            author="Sebastián Gómez Matus"
            authorUrl="sebastian-gomez-matus"
            url="como-imagine-bagdad-y-como-la-encontre"
          />
          <Book
            img={ultimoIntento}
            title="El último intento"
            author="Mariel Iribe Zenil"
            authorUrl="mariel-iribe-zenil"
            url="el-ultimo-intento"
          />
          <Book
            img={ciudadInvencible}
            title="La ciudad invencible"
            author="Fernanda Trías"
            authorUrl="fernanda-trias"
            url="la-ciudad-invencible"
          />
          <Book
            img={marcasDelAgua}
            title="Las marcas del agua"
            author="L. M. Oliveira"
            authorUrl="luis-munoz-oliveira"
            url="las-marcas-del-agua"
          />
          <Book
            img={krampImg}
            title="Kramp"
            author="María José Ferrada"
            authorUrl="maria-jose-ferrada"
            url="kramp"
          />
          <Book
            img={nadieEnCasa}
            title="No hay nadie en casa"
            author="Isabel Díaz Alanís"
            authorUrl="isabel-diaz-alanis"
            url="no-hay-nadie-en-casa"
          />
        </div>
      </div>
    );
  }
}

export class BookPerAuthorGrid extends React.Component {
  render() {
    const {author, url} = this.props;

    return (
      <React.Fragment>
        <div className={`${book.bookGrid} ${book.bookGridFour}`}>
          <StaticQuery
            query={BookListGridsQuery}
            render={data => {
              const {allLibrosJson} = data;
              const bookList = allLibrosJson.edges.filter(node => {
                const book = node.node;
                return book.author === author || book.author2 === author;
              }).map(node => {
                const book = node.node;
                const img = BOOK_IMG_MAP[book.url];

                return <Book
                  released={book.released}
                  url={book.url}
                  img={img}
                  title={book.title}
                  author={book.author}
                  authorUrl={url}
                  author2={book.author2}
                  author2Url={book.author2Url}
                  purchaseLinks={book.purchaseLinks}
                />
              });
              return bookList;
            }}
          />
        </div>
      </React.Fragment>

    );
  }
}
