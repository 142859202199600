import React from "react";
import {StaticQuery, graphql} from "gatsby";

import {MainLayout} from "../components/layout"
import {SectionHeader} from "./index";
import ShopifyBook from '../components/shopify/book';

import book from "../styles/book.module.styl";
import shared from "../styles/shared.module.styl";

import estacion from "../images/portadas/estacion_aldebaran.png"
import balara from "../images/portadas/balara.png"
import posteridad from "../images/portadas/la_posteridad.png"
import aquiElSilencio from "../images/portadas/aqui_el_silencio.png"
import algoTanOscuro from "../images/portadas/algo_tan_oscuro.png"
import canibalIlustrado from "../images/portadas/canibal_ilustrado.png"
import buenasCostumbres from "../images/portadas/las_buenas_costumbres.png"
import llegadaLaHora from "../images/portadas/llegada_la_hora.png"
import llegadaLaHora2 from "../images/portadas/llegada2.png"
import minimasDespedidas from "../images/portadas/minimas_despedidas.png"
import naturalezaMuerta from "../images/portadas/naturaleza_muerta.png"
import todasLasFiestas from "../images/portadas/todas_las_fiestas_de_manana.png"
import quisieraQuedarme from "../images/portadas/quisiera_quedarme_quieta.png"
import evelyn from "../images/portadas/evelyn.png"
import crucifixion from "../images/portadas/crucifixion.png"
import hijoVirtuoso from "../images/portadas/un_hijo_virtuoso.png"
import cinecdoque from "../images/portadas/cinecdoque.png";
import unMonton from "../images/portadas/un_monton_de_escritura.png"
import matateAmor from "../images/portadas/matate_amor.png";
import ficcionesLudicas from "../images/portadas/ficciones_ludicas.png";
import ficcionesLudicas2 from "../images/portadas/ficciones2.png";
import residuos from "../images/portadas/residuos.png";
import deltaDeSol from "../images/portadas/delta_de_sol.png"
import barrioLento from "../images/portadas/barrio_lento.png"
import nancy from "../images/portadas/nancy.png"
import laAzotea from "../images/portadas/la_azotea.png";
import enCuarentena from "../images/portadas/en_cuarentena.png";
import constelaciones from "../images/portadas/constelaciones.png"
import memoriasTullidas from "../images/portadas/memorias_tullidas.png";
import elMismoPolvo from "../images/portadas/mismo_polvo.png";
import yogaYCoca from "../images/portadas/yoga_y_coca.png";
import cancerofoba from "../images/portadas/cancerofoba.png";
import solDeMonterrey from "../images/portadas/sol_de_monterrey.png";
import desertar from "../images/portadas/desertar.png";
import obeliscos from "../images/portadas/obeliscos.png";
import hipocampo from "../images/portadas/hipocampo.png";
import andamosPerras from "../images/portadas/andamos_perras.png";
import arquitecturaIndi from "../images/portadas/arquitectura_indisciplinada.png";
import tresTruena from "../images/portadas/tres_truenos.png";
import fueraDeFoco from "../images/portadas/fuera_de_foco.png";
import contrapunta from "../images/portadas/contrapunto.png";
import pequenasManifestaciones from "../images/portadas/pequenas_manifestaciones.png";
import noMeVan from "../images/portadas/no_me_van.png";
import matateAmorSegunda from "../images/portadas/matate_amor_segunda.png";
import debilMental from "../images/portadas/la_debil_mental.png";
import estoEsUnEnsayo from "../images/portadas/Esto-es-un-ensayo.jpg";
import cuandoMientenLasEstrellas from "../images/portadas/Cuando-mienten-las-estrellas.png";
import sombraDelMaguey from "../images/portadas/La-sombra-del-maguey.png";
import pequenaIgnorancia from "../images/portadas/La-pequeña-inorancia.png";
import azucarImpalpable from "../images/portadas/azucar-Im.png";
import krampImg from "../images/portadas/kramp.png";
import ciudadInvencible from "../images/portadas/la-ciudad-invencible.png";
import nadieEnCasa from "../images/portadas/No-hay-nadie-en-casa.png";
import marcasDelAgua from "../images/portadas/Las-marcas.png";
import ultimoIntento from "../images/portadas/el_ultimo_intento.png";
import recenPormi from "../images/portadas/recen_por_mi.png";
import algunasVerdades from "../images/portadas/algunas_verdades.png";
import comoImagine from "../images/portadas/como_imagine_bagdad.png";
import misAmigasEstan from "../images/portadas/mis_amigas_estan_cansadas.png"
import rawAge from "../images/portadas/raw_age.png"

// Banners
import bannerAldebaran from "../images/banners/aldebaran.png";
import bannerAlgoTanOscuro from "../images/banners/algo_tan_oscuro.png";
import bannerAquiElSilencio from "../images/banners/aqui_el_silencio.png";
import bannerBalara from "../images/banners/balara.png";
import bannerCanibal from "../images/banners/canibal.png";
import bannerCinecdoque from "../images/banners/cinecdoque.png";
import bannerCrucifixion from "../images/banners/crucifixion.png";
import bannerEvelyn from "../images/banners/evelyn.png";
import bannerFiestas from "../images/banners/fiestas.png";
import bannerHijoVirtuoso from "../images/banners/hijo_virtuoso.png";
import bannerPosteridad from "../images/banners/la_posteridad.png";
import bannerCostumbres from "../images/banners/las_buenas_costumbres.png";
import bannerLlegadaLaHora from "../images/banners/llegada_la_hora.png";
import bannerLlegadaLaHora2 from "../images/banners/banner_llegada2.png";
import bannerMatate from "../images/banners/matate_amor.png";
import bannerMinimas from "../images/banners/minimas_despedidas.png";
import bannerNaturaleza from "../images/banners/naturaleza.png";
import bannerUnMonton from "../images/banners/un_monton.png";
import bannerFiccionesLudicas from "../images/banners/ficciones_ludicas.png";
import bannerFiccionesLudicas2 from "../images/banners/banner_ficciones2.png";
import bannerQuisieraQuedarmeQuieta from "../images/banners/quisiera_quedarme_quieta.png";
import bannerResiduos from "../images/banners/residuos.png"
import bannerDeltaDeSol from "../images/banners/delta_de_sol.png"
import bannerBarrioLento from "../images/banners/barrio_lento.png"
import bannerNancy from "../images/banners/nancy.png"
import bannerLaAzotea from "../images/banners/la_azotea.png"
import bannerEnCuarentena from "../images/banners/banner_en_cuarentena.png"
import bannerConstelaciones from "../images/banners/los_nombres_de_las_constelaciones.png";
import bannerMemoriasTullidas from "../images/banners/memorias_tullidas.png";
import bannerelMismoPolvo from "../images/banners/mismo_polvo.png";
import banneryogaYCoca from "../images/banners/yoga_y_coca.png";
import bannercancerofoba from "../images/banners/cancerofoba.png";
import bannersolDeMonterrey from "../images/banners/sol_de_monterrey.png";
import bannerdesertar from "../images/banners/desertar.png";
import bannerobeliscos from "../images/banners/obeliscos.png";
import bannerhipocampo from "../images/banners/hipocampo.png";
import bannerAndamosPerras from "../images/banners/andamos_perras_banner.png";
import bannerArquitecturaIndi from "../images/banners/arquitectura_indisciplinada_banner.png";
import tresTruenaBanner from "../images/banners/tres_truenos_banner.png";
import fueraDeFocoBanner from "../images/banners/fuera_de_foco_banner.png";
import contrapuntaBanner from "../images/banners/contrapunto_banner.png";
import pequenasManifestacionesBanner from "../images/banners/pequenas_manifestaciones_banner.png";
import noMeVanBanner from "../images/banners/no_me_van_banner.png";
import matateAmorSegundaBanner from "../images/banners/matate_amor_segunda_banner.png";
import debilMentalBanner from "../images/banners/la_debil_mental_banner.png";
import bannerestoEsUnEnsayo from "../images/banners/Esto-es-un-ensayo-BACK.jpg";
import bannercuandoMientenLasEstrellas from "../images/banners/Cuando-mienten-las-estrellas-BACK.png";
import bannersombraDelMaguey from "../images/banners/La-sombra-del-maguey-BACK.png";
import bannerpequenaIgnorancia from "../images/banners/La-pequeña-ignorancia-BACK.png";
import bannerazucarImpalpable from "../images/banners/Banner-web-Azucar.png";
import bannerkrampImg from "../images/banners/Banner-web-Kramp-v2.png";
import bannerciudadInvencible from "../images/banners/Banner-web-La-ciudad-invencible.png";
import bannernadieEnCasa from "../images/banners/No-hay-nadie-en-casa-BACK.png";
import bannermarcasDelAgua from "../images/banners/Banner-web-Marcas.png";
import bannerultimoIntento from "../images/banners/banner_el_ultimo_intento.png";
import bannerComoMeImagine from "../images/banners/como_imagine_bagdad_banner.png";
import bannerAlgunasVerdades from "../images/banners/algunas_verdades_banner.png";
import bannerRecenPorMi from "../images/banners/recen_por_mi_banner.png";
import bannerMisAmigasEstan from "../images/banners/Banner_web_MisAmigas.png"
import bannerRawAge from "../images/banners/banner_raw.png";

import { Helmety } from "../components/Head";


export const BookListQuery = graphql`
  query ListaLibrosQuery {
    allLibrosJson(sort: {fields: launchDate, order: DESC}) {
      edges {
        node {
          launchDate(formatString: "MMMM, YYYY", locale: "es")
          author
          author2
          img
          title
          released
          authorUrl
          author2Url
          shopifyId
          url
          purchaseLinks {
            amazon
          }
        }
      }
    }
  }
`;

export const BOOK_IMG_MAP = {
    "estacion-aldebaran": estacion,
    "cinecdoque": cinecdoque,
    "balara-asgard": balara,
    "la-posteridad": posteridad,
    "naturaleza-muerta": naturalezaMuerta,
    "evelyn": evelyn,
    "aqui-el-silencio": aquiElSilencio,
    "algo-tan-oscuro": algoTanOscuro,
    "llegada-la-hora": llegadaLaHora,
    "llegada-la-hora-2a-edicion": llegadaLaHora2,
    "minimas-despedidas": minimasDespedidas,
    "un-hijo-virtuoso": hijoVirtuoso,
    "las-buenas-costumbres": buenasCostumbres,
    "todas-las-fiestas": todasLasFiestas,
    "crucifixion-en-la-plaza": crucifixion,
    "canibal-ilustrado": canibalIlustrado,
    "quisiera-quedarme-quieta": quisieraQuedarme,
    "matate-amor": matateAmor,
    "un-monton-de-escritura-para-nada": unMonton,
    "ficciones-ludicas": ficcionesLudicas,
    "ficciones-ludicas-2a-edicion": ficcionesLudicas2,
    "residuos": residuos,
    "barrio-lento": barrioLento,
    "nancy": nancy,
    "delta-de-sol": deltaDeSol,
    "la-azotea": laAzotea,
    "en-cuarentena": enCuarentena,
    "los-nombres-de-las-constelaciones": constelaciones,
    "memorias-tullidas": memoriasTullidas,
    "el-mismo-polvo": elMismoPolvo,
    "yoga-y-coca": yogaYCoca,
    "cancerofoba": cancerofoba,
    "sol-de-monterrey": solDeMonterrey,
    "desertar": desertar,
    "obeliscos": obeliscos,
    "hipocampo": hipocampo,
    "andamos-perras-andamos-diablas": andamosPerras,
    "arquitectura-indisciplinada": arquitecturaIndi,
    "tres-truenos": tresTruena,
    "fuera-de-foco": fueraDeFoco,
    "contrapunto": contrapunta,
    "pequenas-manifestaciones-de-luz": pequenasManifestaciones,
    "no-me-van-a-agarrar-durmiendo": noMeVan,
    "matate-amor-segunda": matateAmorSegunda,
    "la-debil-mental": debilMental,
    "esto-es-un-ensayo": estoEsUnEnsayo,
    "cuando-mienten-las-estrellas": cuandoMientenLasEstrellas,
    "la-sombra-del-maguey": sombraDelMaguey,
    "la-pequena-ignorancia": pequenaIgnorancia,
    "azucar-impalpable": azucarImpalpable,
    "kramp": krampImg,
    "la-ciudad-invencible": ciudadInvencible,
    "no-hay-nadie-en-casa": nadieEnCasa,
    "las-marcas-del-agua": marcasDelAgua,
    "el-ultimo-intento": ultimoIntento,
    "recen-por-mi": recenPormi,
    "algunas-verdades": algunasVerdades,
    "como-imagine-bagdad-y-como-la-encontre": comoImagine,
    "mis-amigas-estan-cansadas": misAmigasEstan,
    "raw-age-la-hora-cruda": rawAge
};

export const BOOK_BANNER_MAP = {
  "estacion-aldebaran": bannerAldebaran,
  "cinecdoque": bannerCinecdoque,
  "balara-asgard": bannerBalara,
  "la-posteridad": bannerPosteridad,
  "naturaleza-muerta": bannerNaturaleza,
  "evelyn": bannerEvelyn,
  "aqui-el-silencio": bannerAquiElSilencio,
  "algo-tan-oscuro": bannerAlgoTanOscuro,
  "llegada-la-hora": bannerLlegadaLaHora,
  "llegada-la-hora-2a-edicion": bannerLlegadaLaHora2,
  "minimas-despedidas": bannerMinimas,
  "un-hijo-virtuoso": bannerHijoVirtuoso,
  "las-buenas-costumbres": bannerCostumbres,
  "todas-las-fiestas": bannerFiestas,
  "crucifixion-en-la-plaza": bannerCrucifixion,
  "canibal-ilustrado": bannerCanibal,
  "quisiera-quedarme-quieta": bannerQuisieraQuedarmeQuieta,
  "matate-amor": bannerMatate,
  "ficciones-ludicas": bannerFiccionesLudicas,
  "ficciones-ludicas-2a-edicion": bannerFiccionesLudicas2,
  "un-monton-de-escritura-para-nada": bannerUnMonton,
  "residuos": bannerResiduos,
  "barrio-lento": bannerBarrioLento,
  "nancy": bannerNancy,
  "delta-de-sol": bannerDeltaDeSol,
  "la-azotea": bannerLaAzotea,
  "en-cuarentena": bannerEnCuarentena,
  "los-nombres-de-las-constelaciones": bannerConstelaciones,
  "memorias-tullidas": bannerMemoriasTullidas,
  "el-mismo-polvo": bannerelMismoPolvo,
  "yoga-y-coca": banneryogaYCoca,
  "cancerofoba": bannercancerofoba,
  "sol-de-monterrey": bannersolDeMonterrey,
  "desertar": bannerdesertar,
  "obeliscos": bannerobeliscos,
  "hipocampo": bannerhipocampo,
  "andamos-perras-andamos-diablas": bannerAndamosPerras,
  "arquitectura-indisciplinada": bannerArquitecturaIndi,
  "tres-truenos": tresTruenaBanner,
  "fuera-de-foco": fueraDeFocoBanner,
  "contrapunto": contrapuntaBanner,
  "pequenas-manifestaciones-de-luz": pequenasManifestacionesBanner,
  "no-me-van-a-agarrar-durmiendo": noMeVanBanner,
  "matate-amor-segunda": matateAmorSegundaBanner,
  "la-debil-mental": debilMentalBanner,
  "esto-es-un-ensayo": bannerestoEsUnEnsayo,
  "cuando-mienten-las-estrellas": bannercuandoMientenLasEstrellas,
  "la-sombra-del-maguey": bannersombraDelMaguey,
  "la-pequena-ignorancia": bannerpequenaIgnorancia,
  "azucar-impalpable": bannerazucarImpalpable,
  "kramp": bannerkrampImg,
  "la-ciudad-invencible": bannerciudadInvencible,
  "no-hay-nadie-en-casa": bannernadieEnCasa,
  "las-marcas-del-agua": bannermarcasDelAgua,
  "el-ultimo-intento": bannerultimoIntento,
  "recen-por-mi": bannerRecenPorMi,
  "algunas-verdades": bannerAlgunasVerdades,
  "como-imagine-bagdad-y-como-la-encontre": bannerComoMeImagine,
  "mis-amigas-estan-cansadas": bannerMisAmigasEstan,
  "raw-age-la-hora-cruda": bannerRawAge
};

export default class Libros extends React.Component {
  render () {

    return (
      <MainLayout>
        <Helmety
          url="https://www.dharmabooks.com.mx/libros/"
          title="Nuestro catálogo - Dharma Books"
          content="Libro objeto a precio discreto."
          imgURL="https://www.dharmabooks.com.mx/static_resources/otros/catalogo.png"
        />
        <div>
          <div className={shared.container}>
            <div className={shared.content}>
              <SectionHeader header="Catálogo" />
            </div>
          </div>
          <div className={`${book.bookGrid} ${book.bookGridFive} ${book.bigGrid}`}>
            <StaticQuery
              query={BookListQuery}
              render={data => {
                const {allLibrosJson} = data;
                const bookList = allLibrosJson.edges.map(node => {
                  const book = node.node;
                  const img = BOOK_IMG_MAP[book.url];

                  return <ShopifyBook
                    shopifyId={book.shopifyId}
                    launchDate={book.launchDate}
                    released={book.released}
                    url={book.url}
                    img={img}
                    title={book.title}
                    author={book.author}
                    author2={book.author2}
                    authorUrl={book.authorUrl}
                    author2Url={book.author2Url}
                    purchaseLinks={book.purchaseLinks}
                    showOverlay={false}
                  />
                });
                return bookList;
              }}
            />
          </div>
        </div>
      </MainLayout>
    )
  }
}

